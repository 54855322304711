import dynamic from 'next/dynamic'
import Providers from "@/configuration/next-ui-provider";
import { GoogleAnalytics } from '@next/third-parties/google'

const Footer = dynamic(() => import("./footer/footer"), {ssr: false});
const MainHeader = dynamic(() => import("./main-header/main-header"), {ssr: false});
const TopHeader = dynamic(() => import("./top-header/top-header"), {ssr: false});
import Head from "next/head";
import {GlobalSettings} from "@/configuration/global-settings";

export default function Layout({children, sharedService}) {
    return (
        <>
            <Head>
                <link rel="icon" href="/assets/images/favicon.png" sizes="any"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta charSet="utf-8"/>
            </Head>
            <Providers>
                <TopHeader sharedService={sharedService}/>
                <MainHeader sharedService={sharedService}/>
                {/*TODO Add max width for large screen*/}
                <div className="!bg-custom">
                    <div className="max-w-[1600px] mx-auto px-3 md:px-[50px]">
                        <main>{children}</main>
                        {process.env.NEXT_PUBLIC_ANALYTICS_ID && <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_ANALYTICS_ID}/>}
                    </div>
                </div>
                <Footer sharedService={sharedService}/>
            </Providers>
        </>
    );
}