import React, {useState, useEffect} from 'react';
import {Button} from "@nextui-org/react";
import {FaChevronUp} from "react-icons/fa6";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Show the button when the user scrolls down 100px
    const toggleVisibility = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set up a scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Button
            aria-label="Scroll To Top"
            isIconOnly
            size="sm"
            radius="full"
            onClick={scrollToTop}
            className={`${
                isVisible ? 'flex' : 'hidden'
            } fixed bottom-2 right-2 text-white hover:bg-title-section z-[10000] h-10 w-10 bg-primary`}
        >
            <FaChevronUp className="h-8 w-8 p-1 text-white"/>
        </Button>
    );
};

export default ScrollToTopButton;
